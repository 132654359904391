import "owl.carousel"
import moment from 'moment'
import "./juicer"


jQuery(document).ready(function ($) {

  // Cursor
  const cursor = $('#cursor')

  $(document).on('mousemove', function (e) {
    cursor.css({
      'display': 'block',
      'left': e.pageX + 'px',
      'top': e.pageY + 'px'
    });
  });

  // Instagram
  $("#instagram-feed").juicer()

  // Scroll
  $('.scroll').on('click', function (e) {
    e.preventDefault();

    var aid = $(this).attr("href");
    $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
  })


  // MUSIC CAROUSEL
  function createMusicCarousel() {
    const musicCarousel = $("#music-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    musicCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      center: true,
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createMusicCarousel()

  // VIDEO CAROUSEL
  function createVideoCarousel() {
    const videoCarousel = $("#video-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    videoCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      center: true,
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createVideoCarousel()


  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $(".nav-wrapper").toggleClass('active')
    $("#navigation").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-item').on('click', function () {
    if ($('#navigation').hasClass('active')) {
      $(".nav-wrapper").toggleClass('active')
      $("#navigation").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })
});